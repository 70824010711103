@use  "../../core-ui/_base"
//CONTACT 
.not-found
    padding: 1.5rem
    display: flex
    flex-direction: column
    gap: 2rem
    margin-top: 20%
    @media (min-width: base.$lg-width)
        grid-column: span 3
        margin-top: 2%
    h2
        font-size: 2.5rem
        letter-spacing: 1px
    p
        font-size: 1.5rem
    a
        background-color: transparent
        border-width: 1px
        border-color: base.$pinkish
        border-style: solid
        letter-spacing: 1px
        padding: 1rem
        letter-spacing: 1px
        width: 15rem
        max-width: 50%
        text-align: center
        font-family: base.$body-font
        opacity: 0.9
        background: linear-gradient(to right, base.$white 50%, transparent 50%)
        background-size: 200% 100%
        background-position: right bottom
        transition: all 0.4s ease
    @media (min-width: base.$sm-width)
        padding: 2rem
        h2
            font-size: 4rem
    @media (min-width: base.$lg-width)
        width: 70%
    

    