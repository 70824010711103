@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500&display=swap')
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap')
//Breakpoints
$sm-width: 576px
$md-width: 768px
$lg-width: 992px
$xl-width: 1200px
$xxl-with: 1400px
//Font
$title-font: 'Barlow', sans-serif
$body-font: 'Inter', sans-serif
$codingfont: 'La Belle Aurore', cursive
//Colors
$black-bg: #1d1d1d
$pinkish: #fd1056
$white: #fff
$gray: #8d8d8d
$darkgray: #2b2b2b
//Hide Scrollbar

html::-webkit-scrollbar
    width: 0 !important
    -ms-overflow-style: none
    scrollbar-width: none

body
    background-color: $black-bg
    position: relative
    margin: 0
    padding: 0
    box-sizing: border-box

img
    display: block

a
    text-decoration: none
    cursor: pointer
h1, h2, h3, h4
    margin: 0
    position: relative
ul, li, ol
    padding: 0
    margin: 0
li
    counter-increment: li
    display: list-item
button
    cursor: pointer

p
    font-family: $body-font
    font-weight: 100
//BASICS
.title-font
    font-family: $title-font
    font-weight: 500
.header-font
    font-family: $title-font
.white-text
    color: $white
.pink-text
    color: $pinkish
.gray-text
    color: $gray

// TAG STYLING
.h1-tag:before
    content: "<h1>"
.h1-tag:after
    content: "<h1/>"
.h2-tag:before
    content: "<h2>"
.h2-tag:after
    content: "<h2/>"
.h3-tag:before
    content: "<h3>"
.h3-tag:after
    content: "<h3/>"
.p-tag:before
    content: "<p>"
.p-tag:after
    content: "<p/>"
.section-tag:before
    content: "<section>"
.section-tag:after
    content: "<section/>"
.ol-tag:before
    content: "<ol>"
.ol-tag:after
    content: "<ol/>"
.form-tag:before
    content: "<form>"
.form-tag:after
    content: "<form/>"
.h2-tag:before, .h3-tag:before, .h1-tag:before, .p-tag:before, .ol-tag:before, .form-tag:before
    display: block
    margin-top: 2rem
    font-size: 2rem
    color: $gray
    font-family: $codingfont
    opacity: 0.3
.h2-tag:after, .h3-tag:after, .h1-tag:after, .p-tag:after, .ol-tag:after, .form-tag:after
    display: inline
    margin-left: 2rem
    font-size: 2rem
    color: $gray
    font-family: $codingfont
    opacity: 0.3
.p-tag:before, .p-tag:after, .ol-tag:before, .ol-tag:after, .form-tag:before, .form-tag:after
    font-size: 1.5rem
.section-tag:before
    font-size: 1.5rem
    display: inline
    position: absolute
    transform: translate(0, -30%)
    margin-top: -2rem
    font-size: 2rem
    color: $gray
    font-family: $codingfont
    opacity: 0.3
.section-tag:after
    font-size: 1.5rem
    display: inline
    position: absolute
    transform: translate(0, -110%)
    top: 110%
    right: 0
    font-size: 2rem
    color: $gray
    font-family: $codingfont
    opacity: 0.3
